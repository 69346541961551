.availabilityRequestDialog-doctorDetail {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
}

#availabilityRequestDialog-appointmentDetailsContainer {
    margin: 20px 0 20px 0;
}

.availabilityRequestDialog-datetimePicker {
    margin-top: 5px;
    padding: 10px;
    font-family: inherit;
    font-size: 15px;
}