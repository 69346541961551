body {
    margin: 0 auto;
    font-family: 'roboto';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.logoText {
    font-weight: 400;
    color: #1261a0;
}

.primaryColor {
    color: #1261a0;
}

.primaryTextColor {
    color: #222222;
}

.secondaryTextColor {
    color: #333333;
}

.primaryBackgroundColor {
    background-color: #1261a0;
}

.pageTitle {
    color: #232323;
    font-size: 22px;
    font-weight: 500;
}

.pageSubTitle {
    color: #232323;
    font-size: 18px;
    font-weight: 500;
}

.hasIcon {
    display: flex;
    align-items: center;
}

.link {
    color: #2A7FBA;
    text-decoration: none;
    cursor: pointer;
}

.errorMessage {
    width: 100%;
    color: #ff6961;
    text-align: center;
    font-size: 15px;
}

/* No results in tables */
.noResults {
    margin: 20px 0 20px 0;
    font-size: 17px;
    text-align: center;
}

.tableHeader {
    background-color: #1261a0 !important;
    color: white;
}

.tableHeader th {
    background-color: #1261a0 !important;
    color: white;
}