#referpatientpage-body {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px 10px 0 10px;
}

#referpatientpage-searchContainer {
    margin-bottom: 20px;
    display: flex;
    align-items: top;
}

/* Appointment scheduling dialog contents */
#referpatientpage-doctorDetailsContainer {
    margin: 20px 0 20px 0;
}